import React, { useMemo } from 'react'
import Marquee from 'react-marquee-slider'
// Types
import { SectionBaseProps, SectionJoinTeam } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button, Image } from '../../../atoms'
import { ContentMd } from '../../commons'
import { Link as GatsbyLink } from 'gatsby'

export interface JoinTeamProps extends SectionBaseProps {
  data: SectionJoinTeam
}

const JoinTeam: React.FC<JoinTeamProps> = ({ data }) => {
  const { id, sectionId, headline, openPositions, description, button, contactMessage, teamMembers } = data

  const renderers = useMemo(() => {
    return {
      p: (props: any) => (
        <Box mt={5}>
          <Text {...props} fontSize={2} color={'dark'} />
        </Box>
      ),
      a: ({ href, ...otherProps }: { href: string | undefined }) => {
        if (href && (href.startsWith('/') || href.startsWith('?') || href.startsWith('#'))) {
          return (
            <GatsbyLink to={href} style={{ display: 'inline' }}>
              <Text as={'span'} fontSize={'inherit'} textDecoration={'underline'} {...otherProps} />
            </GatsbyLink>
          )
        }
        return (
          <a href={href} target='_blank' style={{ display: 'inline' }}>
            <Text as={'span'} fontSize={'inherit'} textDecoration={'underline'} {...otherProps} />
          </a>
        )
      },
      code: () => {
        return null
      },
      blockquote: () => {
        return null
      },
    }
  }, [])

  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Row>
          <Col xs={12} md={6}>
            <Heading as={'h3'}>{headline}</Heading>
          </Col>
          <Col xs={12} md={6}>
            <Box mt={[7, 8, 0]} p={[5, 6, 7]} bg={'lightBlueShade'} borderRadius={['regular', 'medium']}>
              <Flex alignItems={'center'} flexWrap={['wrap', 'nowrap']}>
                <Box flex={['0 0 100%', '1']}>
                  {!!openPositions && (
                    <Text fontSize={4} fontWeight={'semi'}>
                      {openPositions}
                    </Text>
                  )}
                  {!!description && <Text fontSize={2}>{description}</Text>}
                </Box>
                {button && (
                  <Box flex={['0 0 100%', '0 0 auto']} ml={[0, 6]} mt={[4, 0]}>
                    <Button {...button} />
                  </Box>
                )}
              </Flex>
              {!!contactMessage && (
                <Box mt={5}>
                  <Box height={1} bg={'dark'} opacity={0.08} />
                  <ContentMd content={contactMessage} renderers={renderers} />
                </Box>
              )}
            </Box>
          </Col>
        </Row>
      </Grid>
      {teamMembers && teamMembers.length > 0 && (
        <Box mt={[60, 70, 80]}>
          <Marquee
            direction={'ltr'}
            velocity={10}
            scatterRandomly={false}
            resetAfterTries={200}
            onInit={() => null}
            onFinish={() => null}
          >
            {teamMembers.map((tm) => (
              <Flex
                key={tm.id}
                display={'inline-flex'}
                width={[80, 104]}
                mx={[2, 4]}
                justifyContent={'center'}
                alignItems={'center'}
                verticalAlign={'middle'}
                borderRadius={'circle'}
                overflow={'hidden'}
              >
                <Image
                  draggable={false}
                  alt={tm.profilePicture.alt || tm.name}
                  image={tm.profilePicture.gatsbyImageData}
                  imgStyle={{
                    borderRadius: '9999px',
                  }}
                />
              </Flex>
            ))}
          </Marquee>
        </Box>
      )}
    </Box>
  )
}

export default JoinTeam
